/** We're following the BEM principle
 * to define class names as .block__element--modifier
 * https://getbem.com/introduction */

/*###################################
##          CSS reset             ##
###################################*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

/*###################################
##          Variables             ##
###################################*/
:root {
  /*------ Colors------ */

  /* neutral */
  --rd-color-background-50: #f7fafc;
  --rd-color-background-75: #f1f5f9;
  --rd-color-background-100: #e0eaf2;
  --rd-color-background-200: #b1cadd;
  --rd-color-background-500: #ffffff;
  /* common */
  --rd-color-common-box-shadow: 0px 3px 5px 0px #d1d1d1;
  --rd-color-common-overlay: #00000099;
  --rd-color-common-primary-disable: #82ccfd;
  --rd-color-common-primary-hover: #0363a3;
  --rd-color-common-primary-outline: #0598fa;
  /* red */
  --rd-color-error-red-50: #fdeeee;
  --rd-color-error-red-100: #fde8e8;
  --rd-color-error-red-200: #fac1c1;
  --rd-color-error-red-300: #f8abab;
  --rd-color-error-red-400: #f16565;
  --rd-color-error-red-main: #eb1e1e;
  --rd-color-error-red-600: #d51313;
  --rd-color-error-red-700: #bd1111;
  --rd-color-error-red-900: #a40f0f;
  /* gradient */
  --text-grad-start-color: #009068;
  --text-grad-end-color: #047ac8;
  --gradient-rainbow: linear-gradient(
    90deg,
    #ff4542 0%,
    #fbaf2d 25%,
    #2cb559 49.48%,
    #0598fa 73.44%,
    #4c46fa 100%
  );
  /* blue */
  --rd-color-primary-blue-50: #e6f5ff;
  --rd-color-primary-blue-100: #b4e0fe;
  --rd-color-primary-blue-200: #82ccfd;
  --rd-color-primary-blue-300: #1da3fb;
  --rd-color-primary-blue-400: #0489e1;
  --rd-color-primary-blue-main: #047ac8;
  --rd-color-primary-blue-600: #036baf;
  --rd-color-primary-blue-700: #035c96;
  --rd-color-primary-blue-900: #023d64;
  /* green */
  --rd-color-success-green-50: #e7fef7;
  --rd-color-success-green-100: #ddfdf4;
  --rd-color-success-green-200: #56e1ba;
  --rd-color-success-green-300: #24cc9e;
  --rd-color-success-green-400: #0ab384;
  --rd-color-success-green-main: #08916b;
  --rd-color-success-green-600: #077b5b;
  --rd-color-success-green-700: #077e5d;
  --rd-color-success-green-900: #044b37;
  /* text */
  --rd-color-text-50: #999999;
  --rd-color-text-75: #686868;
  --rd-color-text-100: #525252;
  --rd-color-text-200: #3c3c3c;
  --rd-color-text-500: #202020;
  /* yello */
  --rd-color-warning-yellow-50: #fff9eb;
  --rd-color-warning-yellow-100: #fff7e4;
  --rd-color-warning-yellow-200: #e8ca7d;
  --rd-color-warning-yellow-300: #ffe4a1;
  --rd-color-warning-yellow-400: #ffce52;
  --rd-color-warning-yellow-main: #db9d00;
  --rd-color-warning-yellow-600: #c08900;
  --rd-color-warning-yellow-700: #aa7a00;
  --rd-color-warning-yellow-900: #946a00;

  /*------ Typography ------ */

  /* common */
  --rd-typography-web-active-link-font-size: 0.875rem;
  --rd-typography-web-active-link-font-weight: 700;
  --rd-typography-web-active-link-line-height: normal;

  --rd-typography-web-button-font-size: 0.875rem;
  --rd-typography-web-button-font-weight: 700;
  --rd-typography-web-button-line-height: normal;

  --rd-typography-web-label-font-size: 0.75rem;
  --rd-typography-web-label-font-weight: 500;
  --rd-typography-web-label-line-height: normal;

  --rd-typography-web-link-font-size: 0.875rem;
  --rd-typography-web-link-font-weight: 500;
  --rd-typography-web-link-line-height: normal;

  --rd-typography-web-placeholder-font-size: 0.75rem;
  --rd-typography-web-placeholder-font-weight: 500;
  --rd-typography-web-placeholder-line-height: normal;

  --rd-typography-web-tag-font-size: 0.75rem;
  --rd-typography-web-tag-font-weight: 700;
  --rd-typography-web-tag-line-height: normal;

  --rd-typography-web-text-font-size: 0.875rem;
  --rd-typography-web-text-font-weight: 500;
  --rd-typography-web-text-line-height: 1.125rem;

  /* large cards  */
  --rd-typography-web-lg-card-subtitle-font-size: 0.875rem;
  --rd-typography-web-lg-card-subtitle-font-weight: 500;
  --rd-typography-web-lg-card-subtitle-line-height: 1.125rem;

  --rd-typography-web-lg-card-title-font-size: 1.125rem;
  --rd-typography-web-lg-card-title-font-weight: 700;
  --rd-typography-web-lg-card-title-line-height: 1.375rem;

  /* main cards  */
  --rd-typography-web-mn-card-subtitle-font-size: 0.75rem;
  --rd-typography-web-mn-card-subtitle-font-weight: 500;
  --rd-typography-web-mn-card-subtitle-line-height: 1rem;

  --rd-typography-web-mn-card-title-font-size: 0.875rem;
  --rd-typography-web-mn-card-title-font-weight: 700;
  --rd-typography-web-mn-card-title-line-height: 1.125rem;

  /* page  */
  --rd-typography-web-main-subtitle-font-size: 0.75rem;
  --rd-typography-web-main-subtitle-font-weight: 500;
  --rd-typography-web-main-subtitle-line-height: 1rem;

  --rd-typography-web-main-title-font-size: 1.25rem;
  --rd-typography-web-main-title-font-weight: 700;
  --rd-typography-web-main-title-line-height: 1.625rem;

  --rd-typography-web-page-subtitle-font-size: 1rem;
  --rd-typography-web-page-subtitle-font-weight: 500;
  --rd-typography-web-page-subtitle-line-height: 1.25rem;

  --rd-typography-web-page-title-font-size: 1.375rem;
  --rd-typography-web-page-title-font-weight: 700;
  --rd-typography-web-page-title-line-height: 1.75rem;

  /* section  */
  --rd-typography-web-section-subtitle-font-size: 0.875rem;
  --rd-typography-web-section-subtitle-font-weight: 500;
  --rd-typography-web-section-subtitle-line-height: normal;

  --rd-typography-web-section-title-font-size: 0.875rem;
  --rd-typography-web-section-title-font-weight: 700;
  --rd-typography-web-section-title-line-height: normal;

  /*------ Settings ------ */
  --rd-layout-grid-horizontal-card-count: 4;
  --rd-layout-page-width: 1600px;
}

body {
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Roboto',
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--rd-color-primary-blue-main);
  border-radius: 20px;
}

button:focus {
  outline: none !important;
}

a:focus {
  outline: none !important;
}

a,
a:link,
a:visited,
a:hover {
  text-decoration: none;
}

/* for keyboard */
button:focus-visible {
  outline: 2px solid var(--rd-color-common-primary-outline) !important;
  outline-offset: 2px !important;
}

a:focus-visible {
  outline: 2px solid var(--rd-color-common-primary-outline) !important;
  outline-offset: 2px !important;
}

ul,
li {
  list-style-type: none;
}

img,
picture,
svg,
video {
  max-width: 100%;
}

input,
textarea,
select,
button,
table,
th,
td,
caption {
  font-family: inherit !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
